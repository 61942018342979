import React from "react";
import Oferta from "../components/Oferta";
import { blocksData } from "../constants/index";
import ContactForm from "../components/ContactForm";
import Team from "../components/Team";
import image from "../static/images/stars-bg.svg";
import OpinieSlider from "../components/OpinieSlider";
import Banner from "../components/Banner"
import { Link as ScrollLink } from "react-scroll";

const StronaGłówna = () => {
  return (
    <>
       <div
      className="pt-[1rem] flex flex-col justify-between items-center text-center text-[4rem] font-extrabold"
      style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <div className="min-h-[80vh]">
        {/* Introductory Text */}
        <p className="text-gold text-[2.5rem] md:text-[3rem] lg:text-[5rem]">
          Twoje wyzwania,
        </p>
        <p className="text-lightOlive text-[2.5rem] md:text-[3rem] lg:text-[5rem] fade-in-out">
        Nasze rozwiązania.
        </p>
        <p
            className="text-lightOlive text-[1rem] fade-in md:mb-9 font-light max-w-[800px] my-16 mx-auto 
            px-6 text-justify leading-relaxed"  // Ensures even sides and proper centering
          >
            Zajmujemy się profesjonalnym tworzeniem aplikacji mobilnych i stron internetowych, które pomagają naszym klientom osiągnąć ich cele biznesowe.
          </p>

        {/* Call to Action Buttons */}
        <div className="flex justify-center gap-6">
          {/* Smooth Scroll Button */}
          <ScrollLink
            to="offer-section" // Add ID to Oferta section
            smooth={true}
            duration={500}
            className="text-darkGray bg-gold hover:text-white px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out cursor-pointer"
          >
            Poznaj naszą ofertę
          </ScrollLink>

          {/* Wycena Button (if you still want it) */}
          
        </div>
      </div>
    </div>
      <Oferta blocks={blocksData} />
      <Team />
      <OpinieSlider />
      <Banner />
      <ContactForm />
    </>
  );
};

export default StronaGłówna;

import React from "react";
import { motion } from "framer-motion"; // Importujemy Framer Motion
import mobile from "../static/images/mobile.webp";
import pageMockUp from "../static/images/pageMockUp.webp";
import Team from "./Team";
import training from "../static/images/training.svg"
import seo from "../static/images/seo.svg"
import technology from "../static/images/technology.svg"
import agile from "../static/images/agile.svg"
import test from "../static/images/test.svg"

// Definiujemy sekwencję animacji dla każdego elementu
const listVariant = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.3, // Opóźnienie między kolejnymi punktami listy
    },
  },
  hidden: { opacity: 0, y: 20 },
};

// Definiujemy animację dla każdego elementu listy
const itemVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  hidden: { opacity: 0, y: 20 },
};

const AboutTheTeam = () => {
  return (
    <section className="bg-lightBeige w-full">
  {/* Banner Section */}
  <section className="banner bg-lightBeige text-darkGray min-h-[50vh] flex flex-col items-center justify-center text-center  w-full">
    <div className="relative flex items-center justify-center my-14 sm:my-20 w-full">
      <hr className="absolute top-1/2 left-0 mx-4 lg:mx-20 right-0 border-t-4 border-gold z-0" />
      <span className="relative bg-lightBeige px-8 text-darkOlive text-3xl md:text-4xl tracking-widest">
        Witamy
      </span>
    </div>
    {/* Sekcja z obrazem jako tłem */}
    <div className="relative flex items-center justify-center bg-parallax lg:h-96 h-80 bg-fixed bg-top lg:bg-top bg-cover w-full">
      {/* Nakładka z ciemnym tłem */}
      <div className="absolute inset-0 bg-black opacity-30" />
      <div className="flex items-center justify-center h-full relative z-1">
        <p className="text-[2rem]  md:text-[2.5rem] lg:text-[3rem] mb-4 backdrop-blur-md bg-white/10 p-6 rounded">
          <span className="font-Gugi ">qubit</span> team
        </p>
      </div>
    </div>
    <section className="min-h-[30vh] grid place-items-center">
  <p className="text-2xl md:text-2xl mx-auto max-w-[90%] sm:max-w-[70%] lg:max-w-[60%] text-justify">
    Tworzymy{" "}
    <span className="text-gold">unikalne strony internetowe</span>, które
    nie tylko zachwycą wyglądem, ale również zapewnią{" "}
    <span className="text-gold">doskonałe wrażenia użytkownika</span>.
    Oferujemy <span className="text-gold">perfekcję</span> w każdym
    detalu.
  </p>
</section>
    <div>
      <div>
        {/* Sekcja z tytułem i rozwinięciem */}
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full min-h-[40vh] my-12 space-y-6 lg:space-y-0 lg:space-x-10 bg-darkGray text-lightOlive  p-8">
          {/* Tytuł po lewej */}
          <h2 className="text-3xl text-lightBeige lg:text-left text-center flex-1">
            Własna strona internetowa z perspektywą rozwoju
          </h2>

          {/* Rozwinięcie po prawej */}
          <p className="text-xl md:text-2xl text-lightOlive text-justify lg:text-left flex-1">
            Aby być zawsze o krok przed konkurencją, nieustannie rozwijamy
            nasze umiejętności, doskonalimy procesy i wprowadzamy innowacje.
            Dążymy do doskonałości na każdym etapie tworzenia stron
            internetowych, ucząc się od najlepszych i monitorując najnowsze
            trendy.
          </p>
        </div>

        {/* Nowa sekcja z blokami */}
        <section className="w-full mx-auto min-h-[70vh] mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Blok 1 */}
          <div className="p-6 bg-lightBeige rounded-lg flex flex-col justify-center items-center text-center">
            <img src={training} alt="szkolenie" />
            <span className="text-gold font-semibold text-xl md:text-2xl mb-2">
              Ciągłe szkolenia z UX/UI
            </span>
            <p className="text-darkGray text-lg md:text-xl">
              Uczestniczymy w warsztatach i kursach, które pomagają nam
              tworzyć intuicyjne i atrakcyjne dla użytkowników strony.
            </p>
          </div>

          {/* Blok 2 */}
          <div className="p-6 bg-lightBeige rounded-lg flex flex-col justify-center items-center text-center">
          <img src={seo} alt="SEO" />
            <span className="text-gold font-semibold text-xl md:text-2xl mb-2">
              Optymalizacja SEO
            </span>
            <p className="text-darkGray text-lg md:text-xl">
              Regularnie aktualizujemy naszą wiedzę na temat SEO, aby strony
              naszych klientów były widoczne w wyszukiwarkach.
            </p>
          </div>

          {/* Blok 3 */}
          <div className="p-6 bg-lightBeige rounded-lg flex flex-col justify-center items-center text-center">
          <img src={technology} alt="technologie nowe" />
            <span className="text-gold font-semibold text-xl md:text-2xl mb-2">
              Śledzenie nowych technologii
            </span>
            <p className="text-darkGray text-lg md:text-xl">
              Jesteśmy na bieżąco z nowymi technologiami, takimi jak AI, aby
              wprowadzać najnowsze rozwiązania na stronach.
            </p>
          </div>

          {/* Blok 4 */}
          <div className="p-6 bg-lightBeige rounded-lg flex flex-col justify-center items-center text-center">
          <img src={agile} alt="agile scrum" />
            <span className="text-gold font-semibold text-xl md:text-2xl mb-2">
              Agile i Scrum
            </span>
            <p className="text-darkGray text-lg md:text-xl">
              Stosujemy elastyczne metodyki pracy, które pozwalają nam
              szybko dostosowywać się do zmieniających się potrzeb klienta.
            </p>
          </div>

          {/* Blok 5 */}
          <div className="p-6 bg-lightBeige rounded-lg flex flex-col justify-center items-center text-center">
          <img src={test} alt="testowanie stron www" />
            <span className="text-gold font-semibold text-xl md:text-2xl mb-2">
              Testowanie A/B
            </span>
            <p className="text-darkGray text-lg md:text-xl">
              Wdrażamy testy A/B, aby na bieżąco optymalizować strony
              internetowe pod kątem konwersji i wydajności.
            </p>
          </div>
        </section>
      </div>
    </div>
  </section>

  <Team />

  {/* Czym się wyróżniamy Section */}
  <section className="mt-10 w-full">
    <div className="max-w-[90%] sm:max-w-[70%] lg:max-w-[60%] mx-auto text-justify">
      
      <h1 className="text-3xl py-10 text-darkOlive text-center mb-6">
        Czym się wyróżniamy?
      </h1>

      {/* Motion div dla listy */}
      <motion.ul
        className="list-none space-y-4 text-darkGray text-[1rem] md:text-[1.125rem] lg:text-[1.25rem]"
        initial="hidden"
        whileInView="visible" // Animacja w trakcie przewijania
        viewport={{ once: false, amount: 0.3 }} // Uruchamia się po częściowym pojawieniu w viewport
        variants={listVariant} // Animacja dla całej listy
      >
        <motion.li variants={itemVariant}>
          ✔️ <strong>Indywidualne podejście do klienta</strong> - Każdy
          projekt dostosowujemy do specyficznych wymagań, oferując unikalne
          i spersonalizowane rozwiązania.
        </motion.li>
        <motion.li variants={itemVariant}>
          ✔️ <strong>Nowoczesne technologie i innowacyjne podejście</strong>{" "}
          - Korzystamy z najnowszych technologii, aby zapewnić szybkie,
          bezpieczne i estetyczne projekty.
        </motion.li>
        <motion.li variants={itemVariant}>
          ✔️ <strong>Doskonała obsługa klienta</strong> - Oferujemy wsparcie
          techniczne po zakończeniu projektu, zapewniając stałą opiekę i
          szybkie reakcje na potrzeby klientów.
        </motion.li>
        <motion.li variants={itemVariant}>
          ✔️ <strong>Optymalizacja i szybkość działania</strong> - Strony i
          aplikacje, które tworzymy, są zoptymalizowane pod kątem szybkości
          i przyjazności SEO.
        </motion.li>
        <motion.li variants={itemVariant}>
          ✔️ <strong>Transparentność procesu współpracy</strong> -
          Przejrzysty proces realizacji projektu z regularnymi raportami i
          stałym kontaktem z klientem.
        </motion.li>
        <motion.li variants={itemVariant}>
          ✔️ <strong>Bezpieczeństwo i ochrona danych</strong> - Stosujemy
          zaawansowane zabezpieczenia i dbamy o przestrzeganie standardów
          ochrony danych, w tym RODO.
        </motion.li>
        <motion.li variants={itemVariant}>
          ✔️ <strong>Skalowalność i elastyczność rozwiązań</strong> - Nasze
          projekty są skalowalne, co pozwala na ich rozwój wraz z potrzebami
          biznesowymi klienta.
        </motion.li>
        <motion.li variants={itemVariant}>
          ✔️ <strong>Krótki czas realizacji</strong> - Szybka realizacja
          projektów, przy jednoczesnym utrzymaniu wysokiej jakości.
        </motion.li>
      </motion.ul>
    </div>
  </section>

  <div className="w-full mb-6 my-10 md:mb-0 flex justify-center items-center">
    <div className="bg-lightBeige overflow-hidden flex flex-col lg:flex-row justify-center items-center">
      <img
        src={pageMockUp}
        alt="employee qubit frontend strony www"
        className="p-0 object-cover w-[400px] m-4"
      />
      <img
        src={mobile}
        alt="qubit employee strony www"
        className="p-0 object-cover w-[400px] m-4"
      />
    </div>
  </div>
</section>
  );
};

export default AboutTheTeam;

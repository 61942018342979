import React from 'react'
import ContactForm from '../components/ContactForm'
import Map from '../components/Map'

const Kontakt = () => {
  return (
    <div>
      <h1 className="lg:font-Montserrat text-lightOlive text-4xl lg:text-5xl font-extralight text-center py-20">Skontaktuj się z nami</h1>
      <ContactForm />
      <Map />
    </div>
  )
}

export default Kontakt

import React from 'react'

const Map = () => {
  return (
    <div>
        <div className="h-96 w-auto px-10">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2380.9025751565723!2d17.489088376247835!3d53.36289847348545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4703b1ce56403fad%3A0x8583424140456673!2sGda%C5%84ska%2031%2C%2089-410%20Wi%C4%99cbork!5e0!3m2!1spl!2spl!4v1725897042316!5m2!1spl!2spl"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Maps Location"
      ></iframe>
    </div>
    </div>
  )
}

export default Map

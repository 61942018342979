import mockupImage from "../static/images/mockup.webp"; // Upewnij się, że ścieżka jest poprawna
import laptop from "../static/images/laptop.webp";
import tablet from "../static/images/office2.webp";
import bgImageOffice from "../static/images/bgImageOffice.png";
import ada from "../static/images/ada.png";
import kuba from "../static/images/kuba.png";
import sandra from "../static/images/sandra.png";
import writing from "../static/images/writing.webp";
import laptopp from "../static/images/office1.webp";
import planer from "../static/images/planer.webp";
import { FaServer, FaClock, FaClipboardList } from "react-icons/fa";

export const navLinks = [
  
  {
    id: "StronaGlowna",
    title: "Strona Główna",
    path: "/",
  },
  {
    id: "O-nas",
    title: "O nas",
    path: "/o-nas",
  },
  {
    id: "Oferta",
    title: "Oferta",
    path: "/oferta",
  },
  {
    id: "Proces",
    title: "Proces",
    path: "/proces",
  },
  {
    id: "Wycena",
    title: "Wycena",
    path: "/wycena",
  },
  {
    id: "Kontakt",
    title: "Kontakt",
    path: "/kontakt",
  },
];

export const blocksData = [
  {
    title: "Strona internetowa",
    description:
      "Tworzymy nowoczesne i responsywne strony internetowe, które przyciągają uwagę i angażują użytkowników. Twoja witryna będzie wyglądać świetnie na każdym urządzeniu.",
  },
  {
    title: "Sklep internetowy",
    description:
      "Zbuduj swój sklep online z łatwością. Nasze rozwiązania e-commerce są intuicyjne, bezpieczne i zoptymalizowane, aby zwiększyć Twoją sprzedaż.",
  },
  {
    title: "Projekt graficzny",
    description:
      "Nasze projekty graficzne łączą estetykę z funkcjonalnością. Od logo po materiały promocyjne, dostosowujemy każdy detal do Twojej marki.",
  },
  {
    title: "Aplikacje mobilne",
    description:
      "Rozwijaj swoją działalność z pomocą aplikacji mobilnych, które są szybkie, responsywne i oferują doskonałe doświadczenia użytkownika na różnych platformach.",
  },
];

export const employees = [
  {
    name: "Kuba",
    position: "Fullstack",
    description: ` Doświadczony programista Full Stack, tworzący niesamowite
                      i innowacyjne produkty. Specjalizuję się w technologii
                      Microsoft. Mam głowę pełną różnych nowoczesnych pomysłów,
                      co sprawia, że nieustannie rozglądam się za możliwymi
                      ulepszeniami, zarówno kodu, jak i procesów, które mnie
                      otaczają. Nie ma projektu, w którym bałbym się zanurzyć,
                      na jakimkolwiek etapie. Nieustannie daję z siebie wszystko
                      i zawsze znajdę rozwiązanie każdego napotkanego problemu.`,
    experience: "4+",
    backend: "80%",
    timeManagement: "20%",
    github: "https://github.com/JakubRoss",
    linkedin: "https://www.linkedin.com/in/jakubross/",
    image: kuba,
    backendIcon: <FaServer />,
    timeManagementIcon: <FaClock />,
    timeIcon: <FaClipboardList />,
  },
  {
    name: "Ada",
    position: "Webdeveloper",
    description: ` Ambitna Web Developerka oraz UI/UX Designerka, którą
                      fascynuje świat technologii i designu. Moje doświadczenie
                      pozwoliło mi na rozwinięcie umiejętności w zakresie
                      kodowania oraz projektowania, a moja determinacja i pasja
                      napędzają mnie do kreowania innowacyjnych, użytecznych i
                      estetycznych rozwiązań online.`,
    experience: "4+",
    frontend: "80%",
    timeManagement: "20%",
    github: "https://github.com/AdriannaLen",
    linkedin: "https://www.linkedin.com/in/adrianna-lenczewska-276185287/",
    image: ada,
    backendIcon: <FaServer />,
    timeManagementIcon: <FaClock />,
    timeIcon: <FaClipboardList />,
  },
  {
    name: "Sandra",
    position: "Webdeveloper",
    description: `  Kreatywna dusza, która od zawsze kochała tworzyć piękne i
                      funkcjonalne rzeczy. Po ukończeniu SDA, skoncentrowałam
                      się na rozwijaniu umiejętności Frontend Developmentu i
                      Graficznego Designu, dążąc do połączenia estetyki z
                      najlepszymi praktykami programistycznymi. Pomimo mojego
                      względnie krótkiego doświadczenia zawodowego, z pasją i
                      determinacją podejmuję wyzwania, dążąc do ciągłego rozwoju
                      i doskonalenia swoich umiejętności.`,
    experience: "4+",
    backend: "80%",
    timeManagement: "20%",
    github: "https://github.com/SandraRosploch",
    linkedin: "https://www.linkedin.com/in/sandra-rosploch/",
    image: sandra,
    backendIcon: <FaServer />,
    timeManagementIcon: <FaClock />,
    timeIcon: <FaClipboardList />,
  },
];
export const projects = [
  { image: mockupImage },
  { image: mockupImage },
  { image: mockupImage },
  { image: mockupImage },
];
export const blocks = [
  {
    title: "Landing Page",
    description:
      "Idealne rozwiązanie, jeśli potrzebujesz jednej, dobrze zoptymalizowanej strony, która spełnia konkretne cele – np. zbieranie leadów, promocję nowego produktu lub usługi.",
    benefits: [
      "Szybki czas realizacji",
      "Skupienie na jednej ofercie",
      "Optymalizacja pod kątem konwersji",
    ],
    scope: [
      "Konsultacja i analiza celów",
      "Wybór motywu lub unikalny projekt",
      "Implementacja treści, obrazów i formularzy",
      "Optymalizacja SEO",
      "Testowanie responsywności",
      "Opcja dodatkowego języka tłumaczonego przez tłumacza przysięgłego",
      "Polityka prywatności i pliki cookies",
    ],
  },
  {
    title: "Strona Wielostronicowa (Multi-Page)",
    description:
      "Rozbudowana strona z wieloma podstronami, idealna dla firm potrzebujących pełnej prezentacji oferty.",
    benefits: [
      "Pełna prezentacja oferty",
      "Łatwa nawigacja",
      "Lepsze pozycjonowanie SEO",
    ],
    scope: [
      "Konsultacja i analiza potrzeb",
      "Tworzenie mapy strony",
      "Projektowanie i dostosowanie motywu",
      "Integracja z blogiem i galerią",
      "Optymalizacja SEO",
      "Testowanie responsywności",
      "Opcja dodatkowego języka tłumaczonego przez tłumacza przysięgłego",
      "Polityka prywatności i pliki cookies",
    ],
  },
  {
    title: "Sklep Internetowy (E-commerce)",
    description:
      "Nowoczesny sklep internetowy oparty na WooCommerce, z pełną integracją płatności.",
    benefits: [
      "Sprzedaż online",
      "Zarządzanie produktami",
      "Automatyzacja procesów sprzedaży",
    ],
    scope: [
      "Konsultacja i analiza rynku",
      "Wybór i dostosowanie motywu e-commerce",
      "Konfiguracja katalogu produktów i płatności",
      "Integracja z bramkami płatniczymi",
      "Konfiguracja automatycznych e-maili",
      "Szkolenie z obsługi sklepu",
      "Testowanie i optymalizacja",
      "Opcja dodatkowego języka tłumaczonego przez tłumacza przysięgłego",
      "Polityka prywatności i pliki cookies",
    ],
  },
  {
    title: "Opcja Wielojęzyczności",
    description:
      "Stworzenie strony w wielu językach, idealne dla firm działających międzynarodowo.",
    benefits: [
      "Zwiększenie zasięgu w różnych krajach",
      "Poprawa SEO w różnych wersjach językowych",
      "Profesjonalny wygląd i intuicyjna nawigacja",
    ],
    scope: [
      "Tłumaczenie treści",
      "Konfiguracja narzędzia do zarządzania językami",
      "Dodanie przycisków przełączania języka",
      "Testowanie poprawności wersji językowych",
      "Polityka prywatności i pliki cookies",
    ],
  },
];
export const items = [
  {
    feature: "Czas realizacji",
    basic: "2 tygodnie",
    advanced: "4 tygodnie",
    premium: "6 tygodni",
  },
  { feature: "Strona główna", basic: "✔", advanced: "✔", premium: "✔" },
  { feature: "Kontakt", basic: "✔", advanced: "✔", premium: "✔" },
  { feature: "O nas", basic: "✔", advanced: "✔", premium: "✔" },
  { feature: "Blog", basic: "✘", advanced: "✔", premium: "✔" },
  { feature: "Galeria", basic: "✘", advanced: "✔", premium: "✔" },
  { feature: "Formularz kontaktowy", basic: "✘", advanced: "✔", premium: "✔" },
  { feature: "Sklep online", basic: "✘", advanced: "✘", premium: "✔" },
  {
    feature: "Integracja z płatnościami",
    basic: "✘",
    advanced: "✘",
    premium: "✔",
  },
  { feature: "Wiele języków", basic: "✘", advanced: "✘", premium: "✔" },
  { feature: "Custom design", basic: "✘", advanced: "✘", premium: "✔" },
  {
    feature: "Zaawansowana personalizacja",
    basic: "✘",
    advanced: "✘",
    premium: "✔",
  },
  {
    feature: "Pełna optymalizacja SEO",
    basic: "✘",
    advanced: "✘",
    premium: "✔",
  },
  { feature: "Analiza i raporty", basic: "✘", advanced: "✘", premium: "✔" },
  {
    feature: "Wsparcie",
    basic: "Bez limitu",
    advanced: "Bez limitu",
    premium: "Bez limitu",
  },
  { feature: "Poprawki", basic: "2", advanced: "4", premium: "6" },
  {
    feature: "Cena",
    basic: "od 1500 PLN*",
    advanced: "od 2500 PLN*",
    premium: "od 5000 PLN*",
  },
];

export const kroki = [
  {
    tytul: "Konsultacja i Analiza Potrzeb",
    opis: "Zrozumienie celów biznesowych, określenie grupy docelowej, analiza rynków zagranicznych (w przypadku opcji wielojęzyczności), oraz wymagań prawnych dotyczących ochrony danych.",
    zdjecie: planer,
    className: "rounded-tl-lg rounded-br-lg", // Zaokrąglenie w lewym górnym i prawym dolnym rogu
  },
  {
    tytul: "Planowanie i Wycena",
    opis: "Opracowanie struktury strony, przygotowanie wstępnej wyceny. Dopasowanie motywu lub stworzenie indywidualnego projektu graficznego.",
    zdjecie: tablet,
    className: "rounded-tr-full", // Pełne zaokrąglenie (okrąg)
  },
  {
    tytul: "Tworzenie Strony o Optymalizacja",
    opis: "Instalacja WordPressa, konfiguracja motywu, dodanie treści i funkcjonalności, implementacja wersji wielojęzycznej, integracja z bramkami płatniczymi oraz wdrożenie polityki prywatności i plików cookies. SEO, prędkość ładowania strony, responsywność, optymalizacja treści w każdym języku, optymalizacja płatności i procesów zakupowych, zgodność z przepisami dotyczącymi prywatności.",
    zdjecie: laptop,
    className: "rounded-lg", // Zaokrąglenie wszystkich rogów
  },
  {
    tytul: "Testowanie",
    opis: "Sprawdzenie działania strony na różnych urządzeniach i przeglądarkach, testowanie poprawności wersji językowych oraz bramek płatniczych, sprawdzenie poprawności działania polityki prywatności i banera cookies.",
    zdjecie: laptopp,
    className: "rounded-tl-full", // Zaokrąglenie tylko lewym górnym rogu
  },
  {
    tytul: "Uruchomienie Strony",
    opis: "Publikacja strony, konfiguracja domeny i hostingu, aktywacja różnych wersji językowych, finalne testy płatności oraz wdrożenie polityki prywatności i banera cookies.",
    zdjecie: writing,
    className: "rounded-bl-full", // Zaokrąglenie tylko lewym dolnym rogu
  },
  {
    tytul: "Szkolenie i Wsparcie",
    opis: "Przekazanie wiedzy na temat zarządzania stroną, wielojęzycznością, obsługą płatności oraz zarządzania polityką prywatności i plikami cookies, dostęp do wsparcia technicznego.",
    zdjecie: bgImageOffice,
    className: "rounded-br-lg", // Zaokrąglenie tylko górnych lub dolnych rogów
  },
];

export const opinie = [
  {
    name: "Jan Kowalski",
    comment:
      '"Świetna usługa! Bardzo profesjonalne podejście i szybkie efekty. Polecam!"',
    photo: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    name: "Anna Nowak",
    comment:
      '"Jestem bardzo zadowolona z konsultacji. Wysoka jakość obsługi i miła atmosfera."',
    photo: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    name: "Marek Wiśniewski",
    comment:
      '"Profesjonalizm w każdym calu. Widać, że znają się na rzeczy. Zdecydowanie warto!"',
    photo: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    name: "Katarzyna Lewandowska",
    comment:
      '"Usługa na najwyższym poziomie. Dzięki Wam udało mi się rozwiązać moje problemy."',
    photo: "https://randomuser.me/api/portraits/women/2.jpg",
  },
];

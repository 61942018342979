import React, { useState, useEffect } from 'react';
import { FaClock, FaMobileAlt, FaEnvelope } from 'react-icons/fa';
import qubit from "../static/images/GlassLogo.svg";
import slideUp from "../static/images/slide-up.svg";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleScroll = () => {
    setIsVisible(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="relative mt-24 w-full text-lightOlive">
      <div className="w-full px-4 lg:px-12 flex flex-col lg:flex-row justify-between">
        {/* Lewa strona z opisem */}
        <div className="lg:w-1/3 w-full mb-8 lg:mb-0 lg:pr-12">
          <div className="footer-logo">
            <img src={qubit} alt="Qubit logo" className="mb-8 w-auto h-[100px]" />
            <p className="mb-4 text-base md:text-lg">
              <span className="text-2xl font-Gugi">qubit</span> to młody zespół specjalizujący się w tworzeniu oprogramowania.
              Zaprojektujemy dla Ciebie nie tylko aplikacje, ale także zapewnimy wsparcie techniczne i rozwój produktu.
            </p>
            <p className="hidden xl:block text-base md:text-lg">
              Jeśli jesteś zainteresowany współpracą, chcesz dowiedzieć się więcej o naszych usługach lub złożyć zamówienie,
              skontaktuj się z nami poprzez formularz kontaktowy lub dane dostępne w zakładce kontakt.
            </p>
          </div>
        </div>
        
        {/* Prawa strona z linkami i kafelkami */}
        <div className="lg:w-2/3 w-full flex flex-col items-end lg:pl-12">
          <div className="w-full">
            {/* Linki w jednej linii */}
            <div className="mb-12 text-center bg-darkGray py-6 px-6 rounded-3xl">
              <ul className="flex flex-col sm:flex-row sm:space-x-8 text-lightOlive">
                <li className="text-lightOlive font-bold mb-4 sm:mb-0">Quick Links:</li>
                <li>
                  <a href="/" className="relative hover:text-gold">Strona główna
                    <span className="absolute left-0 bottom-[-2px] w-full h-[2px] bg-gold transform scale-x-0 origin-left transition-transform duration-300 ease-in-out"></span>
                  </a>
                </li>
                <li>
                  <a href="/o-nas" className="relative hover:text-gold">O Nas
                    <span className="absolute left-0 bottom-[-2px] w-full h-[2px] bg-gold transform scale-x-0 origin-left transition-transform duration-300 ease-in-out"></span>
                  </a>
                </li>
                <li>
                  <a href="/oferta" className="relative hover:text-gold">Oferta
                    <span className="absolute left-0 bottom-[-2px] w-full h-[2px] bg-gold transform scale-x-0 origin-left transition-transform duration-300 ease-in-out"></span>
                  </a>
                </li>
                <li>
                  <a href="/kontakt" className="relative hover:text-gold">Kontakt
                    <span className="absolute left-0 bottom-[-2px] w-full h-[2px] bg-gold transform scale-x-0 origin-left transition-transform duration-300 ease-in-out"></span>
                  </a>
                </li>
              </ul>
            </div>

            {/* Kafelki z informacjami */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Godziny pracy */}
              <div className="flex flex-col items-center bg-darkGray hover:bg-gold border border-gold hover:text-darkGray duration-500 text-lightOlive p-8 rounded-lg">
                <FaClock className="text-3xl mb-4" />
                <div>
                  <p>Poniedziałek - Piątek</p>
                  <p>08:00 - 18:00</p>
                </div>
              </div>
              
              {/* Numer telefonu */}
              <div className="flex flex-col items-center justify-center bg-darkGray hover:bg-gold border border-gold hover:text-darkGray duration-500 text-lightOlive p-8 rounded-lg">
                <FaMobileAlt className="text-3xl mb-4" />
                <a href="tel:+48690363690" className="hover:text-darkGray">+48 690 363 690</a>
              </div>
              
              {/* Email */}
              <div className="flex flex-col items-center justify-center bg-darkGray hover:bg-gold border border-gold hover:text-darkGray duration-500 text-lightOlive p-8 rounded-lg">
                <FaEnvelope className="text-3xl mb-4" />
                <a href="mailto:info@qubit.cm" className="hover:text-darkGray">info@qubit.cm</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dolna sekcja - prawa autorskie */}
      <div className="text-center mt-12 bg-darkGray py-4 px-4 rounded-3xl lg:mx-12">
        <p className="text-lightOlive text-base md:text-lg">
          © 2024. All rights reserved by
          <span className="font-Gugi text-2xl"> qubit</span>
        </p>
        <button onClick={handleOpenModal} className="mt-4 text-lg text-lightOlive no-underline">
          Polityka Prywatności
        </button>
      </div>

      {/* Modal Polityki Prywatności */}
      {isModalVisible && (
        <div className="fixed inset-0 flex justify-center items-center bg-darkGray bg-opacity-50 z-50 p-4">
          <div className="bg-darkGray border text-sm border-gold text-lightOlive p-6 rounded-lg max-w-full w-full sm:max-w-lg sm:p-8 overflow-y-auto max-h-[90vh]">
            <p className="mb-4">Polityka Prywatności</p>
            <p className="mb-4">
              I. Postanowienia ogólne
              <br />
              Niniejsza Polityka Prywatności określa zasady przetwarzania danych osobowych użytkowników korzystających z formularza kontaktowego dostępnego na stronie www.adcode.it
            </p>
            <p className="mb-4">
              II. Administrator danych
              <br />
              Administratorem danych osobowych jest Adrianna Lenczewska z siedzibą pod adresem ul. Gdańska, Więcbork. Można się z nami skontaktować pod adresem e-mail: adalenczewska@adcode.it
            </p>
            <p className="mb-4">
              III. Cel zbierania danych osobowych
              <br />
              Dane osobowe, takie jak imię i adres e-mail, są zbierane wyłącznie w celu kontaktu z użytkownikiem na podstawie przesłanego formularza.
            </p>
            <p className="mb-4">
              IV. Rodzaj przetwarzanych danych osobowych
              <br />
              Przetwarzane dane to imię oraz adres e-mail podany przez użytkownika w formularzu kontaktowym.
            </p>
            <p className="mb-4">
              V. Okres przetwarzania danych osobowych
              <br />
              Dane osobowe będą przetwarzane przez okres niezbędny do realizacji celu, w jakim zostały zebrane, lub do momentu wycofania zgody przez użytkownika.
            </p>
            <p className="mb-4">
              VI. Prawa użytkowników
              <br />
              Użytkownicy mają prawo dostępu do swoich danych, ich sprostowania, usunięcia, ograniczenia przetwarzania oraz przenoszenia danych. Mogą również w każdej chwili wycofać zgodę na przetwarzanie danych, co nie wpłynie na zgodność z prawem przetwarzania przed jej wycofaniem. Wszelkie zgłoszenia należy kierować na adres e-mail: adalenczewska@adcode.it
            </p>
            <p className="mb-4">
              VII. Postanowienia końcowe
              <br />
              Administrator zastrzega sobie prawo do wprowadzenia zmian w niniejszej Polityce Prywatności. Wszelkie zmiany będą publikowane na tej stronie.
            </p>
            <button
              onClick={handleCloseModal}
              className="w-full bg-lightCoral text-lightOlive border border-gold p-3 rounded-lg font-bold hover:text-gold hover:bg-darkGray transition duration-300 mt-4">
              Zamknij
            </button>
          </div>
        </div>
      )}
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed  bottom-6 right-6 p-3 rounded-full transition-colors duration-300 z-[100]"
          aria-label="Scroll to Top"
        >
          <img
            src={slideUp}
            alt="Scroll to Top qubit page"
            className="object-contain w-12 h-12"
          />
        </button>
      )}
    </footer>
  );
};

export default Footer;

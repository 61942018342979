import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Kalendarz = () => {
  const [startDate, setStartDate] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    selectedTime: "",
  });
  const [formStatus, setFormStatus] = useState(""); // Status formularza
  const [showModal, setShowModal] = useState(false); // Status modala

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus("Wysyłanie...");

    const form = new FormData();
    form.append("access_key", "26f27a07-d780-432d-96cb-ed85293d09d3"); // Twój klucz Web3Forms
    form.append("name", formData.name);
    form.append("email", formData.email);
    form.append("date", startDate ? startDate.toLocaleDateString("pl-PL") : "");
    form.append("time", formData.selectedTime);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: form,
      });

      const data = await response.json();

      if (data.success) {
        setFormStatus("Rezerwacja zapisana!");
        setShowModal(true); // Pokazujemy modal
        setTimeout(() => {
          setShowModal(false); // Ukrywamy modal po 3 sekundach
        }, 3000);

        setFormData({
          name: "",
          email: "",
          selectedTime: "",
        });
        setStartDate(null);
        setTimeout(() => {
          setFormStatus("");
        }, 4000);
      } else {
        setFormStatus("Error: " + data.message);
        setShowModal(true); // Pokazujemy modal z błędem
        setTimeout(() => {
          setShowModal(false); // Ukrywamy modal po 3 sekundach
        }, 3000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormStatus("Error: " + error.message);
      setShowModal(true); // Pokazujemy modal z błędem
      setTimeout(() => {
        setShowModal(false); // Ukrywamy modal po 3 sekundach
      }, 3000);
    }
  };

  const getAvailableTimes = () => {
    const times = [];
    for (let hour = 11; hour <= 15; hour++) {
      times.push(`${hour}:00`);
    }
    return times;
  };

  return (
    <section className="bg-lightBeige relative">
      <h1 className="lg:font-Montserrat px-6 font-extralight bg-darkGray py-8 md:py-16 text-lightOlive text-3xl md:text-4xl lg:text-5xl text-center">
        Zarezerwuj Darmową Konsultacje
      </h1>
      <p className="text-center p-6 lg:p-20">
        Jeżeli nie jesteś jeszcze zdecydowany, masz pytania, nie wiesz od czego
        zacząć, mamy dla Ciebie idealne rozwiązanie. Wybierz datę i godzinę na
        darmowe konsultacje, a my udzielimy Ci wszystkich niezbędnych
        informacji.
      </p>
      <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row">
        {/* Kalendarz po lewej stronie */}
        <div className="flex-1 mb-4">
          <label className="mb-2 text-darkOlive">Wybierz datę:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            className="custom-datepicker w-full border focus:border-gold"
            placeholderText="Wybierz datę"
            required
            popperPlacement="bottom"
            shouldCloseOnSelect={false}
          />
          {startDate && (
            <div className="mb-4">
              <label className="mb-2 text-darkOlive">Wybierz godzinę:</label>
              <select
                name="selectedTime"
                value={formData.selectedTime}
                onChange={handleChange}
                className="border p-2 rounded w-auto focus:border-gold"
                required
              >
                <option value="">Wybierz godzinę</option>
                {getAvailableTimes().map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {/* Formularz po prawej stronie */}
        <form onSubmit={handleSubmit} className="flex-1 flex flex-col">
          <div className="mb-4">
            <label className="mb-2 text-darkOlive">Imię i nazwisko:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="border p-2 rounded w-full focus:border-gold"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 text-darkOlive">Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="border p-2 rounded w-full focus:border-gold"
              required
            />
          </div>

          <p className="text-center mb-4 text-darkOlive">{formStatus}</p>

          <button
            type="submit"
            className="text-darkGray bg-gold hover:text-white border px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out w-full"
          >
            Zarezerwuj
          </button>
        </form>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-lg font-semibold mb-4">
              {formStatus === "Rezerwacja zapisana!"
                ? "Rezerwacja została zapisana!"
                : "Wystąpił błąd. Spróbuj ponownie."}
            </h3>
            <p className="text-gray-600">{formStatus}</p>
          </div>
        </div>
      )}
      {/* <div className="flex flex-col lg:flex-row items-center justify-center">
        <img src={pageMockUp} alt="qubit Desktop MockUp" className="w-80"/>
        <img src={mobile} alt="qubit Mobile MockUp" className="w-80"/>
      </div> */}
    </section>
  );
};

export default Kalendarz;

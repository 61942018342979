import React from "react";
import { blocks } from "../constants/index";
import Tabela from "../components/Tabela";
import { Link } from "react-router-dom";
import Accordion from "./Accordion";

const Cennik = () => {
  return (
    <>
      {/* Sekcja "Co oferujemy?" */}
      <section className="relative overflow-hidden bg-darkSoul">
        {/* Napis h1 przecinający górny border */}
        <div className="relative">
          <h1 className="font-Montserrat font-extralight  md:py-16 text-lightOlive text-[2rem] md:text-[2.5rem] lg:text-[3.5rem] text-center mb-8 bg-darkGray w-full relative">
            Co oferujemy?
          </h1>
        </div>
        <div className="relative my-12 md:my-24">
          <div className="flex flex-col flex-wrap justify-center items-center space-y-6 md:space-y-8 lg:space-y-12">
            {blocks.map((block, index) => (
              <div
                key={index}
                className="relative w-full sm:max-w-[70vw] p-4 flex flex-col"
              >
                <div className="relative flex flex-col flex-1 border border-gold rounded-lg shadow-lg overflow-visible">
                  {/* Numeracja */}
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 text-3xl md:text-5xl font-bold text-gold bg-darkSoul">
                    {index + 1}
                  </div>
                  {/* Blok tekstowy */}
                  <div className="flex-1 p-4 pt-16 hover:bg-darkGray text-lightOlive transition-colors duration-500">
                    <h2 className="text-xl md:text-2xl font-bold mb-2">
                      {block.title}
                    </h2>
                    <p className="text-base md:text-lg lg:text-xl mb-4">
                      {block.description}
                    </p>
                    {block.benefits && (
                      <div className="mb-4">
                        <h3 className="text-lg md:text-xl font-semibold mb-2">
                          Zalety:
                        </h3>
                        <ul className="list-disc list-inside text-base md:text-lg lg:text-xl">
                          {block.benefits.map((benefit, i) => (
                            <li key={i} className="mb-2">
                              {benefit}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {block.scope && (
                      <div>
                        <h3 className="text-lg md:text-xl  font-semibold mb-2">
                          Zakres usługi:
                        </h3>
                        <ul className="list-disc list-inside text-base md:text-lg lg:text-xl">
                          {block.scope.map((item, i) => (
                            <li key={i} className="mb-2">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    
      <Tabela />

      <Accordion />
      <div className="flex justify-center w-full bg-gold p-10">
        <Link
          to="/BezplatneKonsultacje"
          className="text-darkGray hover:bg-gold hover:text-white border border-darkGray px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out"
        >
          Zgłoś się na darmowe konsultacje!
        </Link>
      </div>
    </>
  );
};

export default Cennik;

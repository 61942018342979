import React, { useState, useRef } from "react";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]); // Reference to store the height of each content

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionData = [
    {
      title: "Ile kosztuje stworzenie strony internetowej?",
      content:
        "Koszt stworzenia strony internetowej zależy od kilku czynników, takich jak zakres funkcji, design, liczba podstron oraz zaawansowanie technologiczne. Oferujemy różne pakiety dostosowane do potrzeb – od podstawowego (od 1500 PLN) po zaawansowane rozwiązania premium (od 5000 PLN). Po omówieniu szczegółów projektu możemy dostosować ofertę, aby spełniała Twoje oczekiwania i budżet.",
    },
    {
      title: "Ile trwa zbudowanie strony internetowej?",
      content:
        "Czas realizacji zależy od stopnia zaawansowania strony. Pakiet podstawowy realizujemy w ciągu 2 tygodni, zaawansowany w 4 tygodnie, a premium w 6 tygodni. Terminy mogą się nieco wydłużyć, jeśli projekt wymaga dodatkowych funkcji lub personalizacji. Pracujemy jednak zawsze na podstawie uzgodnionych terminów, aby zapewnić terminową dostawę.",
    },
    {
      title: "Czy mogę samodzielnie zarządzać treściami na stronie? (CMS)",
      content:
        "Tak, strony, które tworzymy, opierają się na popularnych systemach CMS (jak WordPress), co umożliwia łatwą edycję treści, dodawanie zdjęć i aktualizowanie informacji bez konieczności posiadania umiejętności programistycznych. Po zakończeniu projektu zapewniamy krótkie szkolenie, które pozwoli Ci zarządzać stroną samodzielnie.",
    },
    {
      title: "Czy mogę później dodawać dodatkowe funkcje do mojej strony?",
      content:
        "Tak, strony internetowe, które budujemy, są elastyczne i łatwe do rozbudowy. Jeśli zdecydujesz się dodać bloga, galerię, dodatkowe języki czy sklep internetowy w przyszłości, możemy to zrobić bez konieczności przebudowy całej strony. Każdy projekt jest skalowalny, co pozwala na rozwijanie funkcjonalności zgodnie z potrzebami Twojej firmy.",
    },
    {
      title: "Czy strona będzie zoptymalizowana pod kątem SEO?",
      content:
        "Tak, optymalizacja SEO jest integralną częścią każdego projektu. Dbamy o to, by Twoja strona była widoczna w wynikach wyszukiwania Google poprzez odpowiednie dostosowanie treści, metadanych, szybkości ładowania oraz struktury strony. Pakiet premium obejmuje pełną optymalizację SEO, ale w każdym z naszych pakietów zadbamy o kluczowe aspekty optymalizacji.",
    },
  ];

  return (
    <div className="w-full lg:w-3/4 mx-auto px-4">
      <h1 className="text-center text-lightOlive bg-darkGray text-3xl py-10">FAQ</h1>
      {accordionData.map((item, index) => (
        <div
          key={index}
          className="mb-6 border border-gold rounded-lg overflow-hidden shadow-sm"
        >
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full text-left p-4 font-medium text-2xl bg-darkGray text-lightOlive hover:bg-darkGray transition-colors duration-300 ease-in-out flex justify-between items-center rounded-lg"
          >
            <span>{item.title}</span>
            <span className="ml-2 text-xl">{activeIndex === index ? "−" : "+"}</span>
          </button>
          <div
            ref={(el) => (contentRefs.current[index] = el)} // Assign the ref to the content
            className="overflow-hidden transition-[max-height] duration-500 ease-in-out"
            style={{
              maxHeight: activeIndex === index ? `${contentRefs.current[index]?.scrollHeight}px` : "0",
            }}
          >
            <p className="p-4 bg-darkGray text-lg text-lightOlive">{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;

import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { employees } from "../constants"; // Importujemy dane pracowników
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Team = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const contentRefs = useRef([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "300px",
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "10px",
        },
      },
    ],
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="px-4 py-10 min-h-[90vh] bg-darkGray flex items-center justify-center">
      <div className="w-full h-full">
        <h1 className="text-center text-[2rem] md:text-[2.5rem] lg:text-[3rem] text-lightOlive mb-8">
          Nasz zespół
        </h1>
        <Slider {...settings}>
          {employees.map((employee, index) => (
            <div key={index} className="px-3 h-full flex items-center">
              <div className="bg-darkGray border border-gold text-white rounded-lg p-6 shadow-lg flex flex-col lg:flex-row items-center w-full h-auto lg:h-[85%]">
                {/* Informacje o pracowniku */}
                <div className="w-full lg:w-1/2 h-full flex flex-col justify-between">
                  <div>
                    <h3 className="text-2xl font-semibold mb-2 lg:text-3xl">
                      {employee.name}
                    </h3>
                    <p className="text-md italic text-gray-400 mb-4 lg:text-xl">
                      {employee.position}
                    </p>
                    <div
                      ref={(el) => (contentRefs.current[index] = el)} // Assign ref to the content
                      className={`text-sm text-gray-300 mb-4 text-justify lg:text-lg transition-all duration-500 overflow-hidden`}
                      style={{
                        maxHeight:
                          expandedIndex === index
                            ? `${contentRefs.current[index]?.scrollHeight}px`
                            : "80px", // Default collapsed height
                        transition: "max-height 0.5s ease",
                      }}
                    >
                      {employee.description}
                    </div>
                    <button
                      onClick={() => toggleExpand(index)}
                      className="text-lightOlive hover:underline"
                    >
                      {expandedIndex === index ? "Zwiń" : "Czytaj więcej"}
                    </button>
                  </div>
                  <div>
                    <div className="mt-10 text-sm text-gray-400 lg:text-lg flex flex-col lg:flex-row lg:space-x-10">
                      <div className="flex items-center space-x-2">
                        <p>Backend: {employee.backend}</p>
                        <div className="text-3xl">{employee.backendIcon}</div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <p>Zarządzanie czasem: {employee.timeManagement}</p>
                        <div className="text-3xl">
                          {employee.timeManagementIcon}
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <p>Doświadczenie: {employee.experience} lat</p>
                        <div className="text-3xl">{employee.timeIcon}</div>
                      </div>
                    </div>

                    <div className="flex space-x-4 text-lightOlive mt-10">
                      <a
                        href={employee.github}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaGithub className="text-2xl lg:text-3xl" />
                      </a>
                      <a
                        href={employee.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin className="text-2xl lg:text-3xl" />
                      </a>
                    </div>
                  </div>
                </div>
                {/* Zdjęcie pracownika */}
                <div className="w-full hidden lg:flex lg:w-1/2 h-full items-center justify-center mt-4 lg:mt-0">
                  <img
                    src={employee.image}
                    alt={employee.name}
                    className="w-40 h-40 md:w-48 md:h-48 object-cover"
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Team;

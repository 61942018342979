import React from 'react';
import Slider from 'react-slick';
import { opinie } from '../constants/index';
import { FaStar } from 'react-icons/fa';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
};

const OpinieSlider = () => {
  return (
      <section className="pb-8 bg-lightBeige flex flex-col justify-center items-center min-h-[70vh]">
      <div className="container mx-auto px-4">
        <h1 className="lg:font-Montserrat font-extralight py-4 text-darkGray text-[2rem] md:text-[2.5rem] lg:text-[3rem] text-center my-8">
          Co mówią nasi klienci
        </h1>
        <Slider {...settings} className="relative">
          {opinie.map((opinia, index) => (
            <div key={index} className="p-4 flex justify-center items-center ">
              <div className="relative flex flex-col bg-lightBeige  justify-center items-center  min-h-[40vh] border border-gold rounded-lg p-6 max-w-md mx-auto">
                <div className="mt-4 text-center"> {/* Adjusted margin for better positioning */}
                  <p className="text-lg md:text-xl text-darkGray font-semibold">{opinia.name}</p>
                  <p className="text-base text-darkGray mt-2">{opinia.comment}</p>
                  <div className="flex justify-center mt-4">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <FaStar
                        key={star}
                        className="text-yellow-400 text-xl md:text-2xl"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* Custom CSS for dots */}
        <style jsx>{`
          .slick-dots {
            bottom: 20px; /* Adjust the distance from the bottom of the container */
          }
        `}</style>
      </div>
    </section>
  );
};

export default OpinieSlider;

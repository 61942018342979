import React from "react";

const Tabela = () => {
  const items = [
    { feature: "Czas realizacji", basic: "2 tygodnie", advanced: "4 tygodnie", premium: "6 tygodni" },
    { feature: "Strona główna", basic: "✔", advanced: "✔", premium: "✔" },
    { feature: "Kontakt", basic: "✔", advanced: "✔", premium: "✔" },
    { feature: "O nas", basic: "✔", advanced: "✔", premium: "✔" },
    { feature: "Blog", basic: "✘", advanced: "✔", premium: "✔" },
    { feature: "Galeria", basic: "✘", advanced: "✔", premium: "✔" },
    { feature: "Formularz kontaktowy", basic: "✘", advanced: "✔", premium: "✔" },
    { feature: "Sklep online", basic: "✘", advanced: "✘", premium: "✔" },
    { feature: "Integracja z płatnościami", basic: "✘", advanced: "✘", premium: "✔" },
    { feature: "Wiele języków", basic: "✘", advanced: "✘", premium: "✔" },
    { feature: "Custom design", basic: "✘", advanced: "✘", premium: "✔" },
    { feature: "Zaawansowana personalizacja", basic: "✘", advanced: "✘", premium: "✔" },
    { feature: "Pełna optymalizacja SEO", basic: "✘", advanced: "✘", premium: "✔" },
    { feature: "Analiza i raporty", basic: "✘", advanced: "✘", premium: "✔" },
    { feature: "Wsparcie", basic: "Bez limitu", advanced: "Bez limitu", premium: "Bez limitu" },
    { feature: "Poprawki", basic: "2", advanced: "4", premium: "6" },
    { feature: "Cena", basic: "od 1500 PLN*", advanced: "od 2500 PLN*", premium: "od 5000 PLN*" }
  ];

  return (
    <div className="bg-lightBeige py-10">
      <div className="container mx-auto px-4">
        <h1 className="font-Montserrat text-3xl md:text-4xl font-extralight mb-6 text-center text-lightOlive p-10">
          Pakiety Stron Internetowych
        </h1>

        {/* Desktop View: Table */}
        <div className="hidden md:block">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-lightBeige border border-gray-300">
              <thead>
                <tr className="border-b border-gray-300">
                  <th className="p-4 font-bold text-left"></th>
                  <th className="p-4 text-2xl md:font-3xl font-bold text-left">Podstawowy</th>
                  <th className="p-4 text-2xl md:font-3xl font-bold text-left">Zaawansowany</th>
                  <th className="p-4 text-2xl md:font-3xl font-bold text-left">Premium</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index} className="border-b border-gray-300 hover:bg-lightOlive">
                    <td className="p-4 font-bold">{item.feature}</td>
                    <td className="p-4 text-center">{item.basic}</td>
                    <td className="p-4 text-center">{item.advanced}</td>
                    <td className="p-4 text-center">{item.premium}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Mobile View: Cards */}
        <div className="md:hidden">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 ">
            {['basic', 'advanced', 'premium'].map((type, idx) => (
              <div key={idx} className="border border-gray-300 rounded-lg bg-lightBeige p-4 shadow-md hover:bg-lightOlive">
                <h2 className="font-bold text-2xl md:font-3xl mb-4 text-center capitalize">
                  {type === 'basic' ? 'Podstawowy' : type === 'advanced' ? 'Zaawansowany' : 'Premium'}
                </h2>
                {items.map((item, index) => (
                  <div key={index} className="flex justify-between mb-2">
                    <span className="">{item.feature}:</span>
                    <span>{item[type]}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <p className="lg:font-Montserrat text-lg text-darkGray text-center py-10">
          *Cena jest zależna od rodzaju wybranej strony i dodatkowych opcji
        </p>
      </div>
    </div>
  );
};

export default Tabela;

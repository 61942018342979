import React from 'react'
import Banner from '../components/Banner'
import AboutTheTeam from '../components/AboutTheTeam'


const Onas = () => {
  return (
    <div>
      <AboutTheTeam />
    <Banner />
    </div>
  )
}

export default Onas
